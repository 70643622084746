<template>
    <div class="l-padded">
        <div class="l-stack l-gap-2 dashboard-reporting-form-view">
            <h3 class="t-title">
                {{ $t('title') }}
            </h3>

            <div class="l-stack l-gap-2">
                <BaseMultiselect
                    v-model="entry"
                    :placeholder="$t('entry')"
                    :allow-empty="false"
                    :options="entries"
                    track-by="id"
                    label="name"
                    block
                />

                <BaseInput
                    v-model="name"
                    :disabled="isSubmitting"
                    :placeholder="$t('name')"
                    block
                />

                <BaseCard padded class="l-stack l-gap-2">
                    <BaseMultiselect
                        v-model="emailType"
                        :options="emailTypeOptions"
                        :custom-label="option => $t(`emailType${option}`)"
                        :disabled="isSubmitting"
                    />

                    <label v-if="emailType === 'CUSTOM'">
                        <p class="form-label">
                            {{ $t('recipient') }}
                        </p>

                        <p v-if="$v.email.$error" class="form-error">
                            {{ $t('emailInvalid') }}
                        </p>

                        <BaseInput
                            v-model="$v.email.$model"
                            :disabled="isSubmitting"
                            :placeholder="$t('email')"
                        />
                    </label>

                    <InfoBox v-else-if="emailType === 'PRIMARY'">
                        {{ $t('emailPrimaryHelp') }}

                        <strong>
                            {{
                                userEmailListFormatted || $t('emailPrimarEmpty')
                            }}
                        </strong>
                    </InfoBox>
                </BaseCard>

                <BaseCard v-if="isDaterange" padded class="l-stack l-gap-2">
                    <div>
                        <p class="form-label">{{ $t('from') }}</p>

                        <div class="l-inline l-gap-2">
                            <DateInput
                                v-model="dateFrom"
                                placeholder="DD.MM.YYYY"
                            />

                            <TimeInput
                                v-model="timeFrom"
                                placeholder="HH:mm"
                                full
                            />
                        </div>
                    </div>

                    <div>
                        <p class="form-label">{{ $t('to') }}</p>

                        <div class="l-inline l-gap-2">
                            <DateInput
                                v-model="dateTo"
                                placeholder="DD.MM.YYYY"
                            />

                            <TimeInput
                                v-model="timeTo"
                                placeholder="HH:mm"
                                full
                            />
                        </div>
                    </div>
                </BaseCard>

                <label class="l-inline l-center-v l-gap-1">
                    <ToggleButton v-model="isStore" redesigned />

                    <span class="t-small">{{ $t('storeLabel') }}</span>
                </label>

                <BaseButton
                    :is-loading="isLoading"
                    :disabled="isDisabled"
                    type="submit"
                    @click="handleSubmit"
                >
                    {{ $t('submit') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import { email } from 'vuelidate/lib/validators'

import { httpHelper } from '@/utils'
import BaseButton from '@/components/redesigned/BaseButton'
import BaseCard from '@/components/redesigned/BaseCard'
import BaseInput from '@/components/redesigned/BaseInput'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import DateInput from '@/components/redesigned/DateInput'
import InfoBox from '@/components/InfoBox'
import TimeInput from '@/components/redesigned/TimeInput'
import ToggleButton from '@/components/ToggleButton'

export default {
    name: 'DashboardReportingFormView',
    components: {
        BaseButton,
        BaseCard,
        BaseInput,
        BaseMultiselect,
        DateInput,
        InfoBox,
        TimeInput,
        ToggleButton,
    },
    data() {
        return {
            dateFrom: null,
            dateTo: null,
            email: '',
            emailType: 'PRIMARY',
            emailTypeOptions: ['PRIMARY', 'CUSTOM'],
            entries: [],
            entry: [],
            isFetching: false,
            isStore: false,
            isSubmitting: false,
            name: '',
            timeFrom: '',
            timeTo: '',
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        isDaterange() {
            return this.entry?.input_type === 'daterange'
        },
        isDisabled() {
            const hasCustomEmail =
                this.emailType === 'CUSTOM' &&
                this.email &&
                !this.$v.email.$error
            const hasPrimaryEmail =
                this.emailType === 'PRIMARY' &&
                this.userInfo.alertsettings.email_recipients.length

            return !(
                (hasCustomEmail || hasPrimaryEmail) &&
                this.entry &&
                this.name &&
                (!this.isDaterange || (this.dateFrom && this.dateTo))
            )
        },
        isLoading() {
            return this.isFetching || this.isSubmitting
        },
        userEmailListFormatted() {
            return this.userInfo.alertsettings.email_recipients.join('; ')
        },
    },
    watch: {
        entry() {
            this.dateFrom = null
            this.dateTo = null

            if (this.entry) {
                this.name = `${this.entry.name} (${moment().format(
                    'DD.MM.YYYY HH:mm'
                )})`
            }
        },
        isDaterange() {
            this.timeFrom = this.isDaterange ? '00:00' : ''
            this.timeTo = this.isDaterange ? '23:59' : ''
        },
    },
    validations() {
        return {
            email: {
                email,
            },
        }
    },
    mounted() {
        this.fetchEntries()
    },
    methods: {
        async fetchEntries() {
            this.isFetching = true
            this.entries = []
            const { data } = await httpHelper.get('/report-queries/')
            if (data.results?.length) {
                this.entries = data.results
            }
            this.isFetching = false
        },
        formatDate(date, time) {
            const template = date.toJSON()
            return template.slice(0, 11) + time + template.slice(16)
        },
        async handleSubmit() {
            this.isSubmitting = true

            await httpHelper.post(this.entry.generate_url, {
                name: this.name,
                store: this.isStore,
                email:
                    this.emailType === 'PRIMARY'
                        ? this.userInfo.alertsettings.email_recipients
                        : [this.email],
                ...(this.isDaterange && {
                    date_from: this.formatDate(this.dateFrom, this.timeFrom),
                    date_to: this.formatDate(this.dateTo, this.timeTo),
                }),
            })

            this.$notify({
                title: this.$t('successNotificationTitle'),
                text: this.$t('successNotificationText'),
            })

            this.dateFrom = null
            this.dateTo = null
            this.email = ''
            this.entry = null
            this.name = ''
            this.timeFrom = ''
            this.timeTo = ''
            this.isSubmitting = false
        },
    },
}
</script>

<i18n>
{
    "en": {
        "email": "E-Mail",
        "emailInvalid": "Invalid E-Mail",
        "emailPrimaryEmpty": "(empty)",
        "emailPrimaryHelp": "Goes to all email addresses from the owner's user profile.",
        "emailTypeCUSTOM": "E-mail to different address",
        "emailTypePRIMARY": "E-Mail to me",
        "entry": "Entry",
        "from": "From",
        "name": "Name",
        "recipient": "Recipient",
        "storeLabel": "Save report data",
        "submit": "Generate",
        "successNotificationText": "The report has been created.",
        "successNotificationTitle": "Successfully!",
        "title": "Create a new report",
        "to": "To"
    },
    "de": {
        "email": "E-Mail",
        "emailInvalid": "Ungültige E-Mail",
        "emailPrimarEmpty": "(leer)",
        "emailPrimaryHelp": "Geht an alle E-Mail Adressen aus Ihrem Benutzerprofil:",
        "emailTypeCUSTOM": "E-Mail an abweichende Adresse",
        "emailTypePRIMARY": "E-Mail an mich",
        "entry": "Eintrag",
        "from": "Von",
        "name": "Name",
        "recipient": "Empfänger",
        "storeLabel": "Reportsdaten speichern",
        "submit": "Generieren",
        "successNotificationText": "Der Report wurde erstellt.",
        "successNotificationTitle": "Erfolgreich!",
        "title": "Einen neuen Report erstellen",
        "to": "Bis"
    },
    "fr": {
        "email": "E-mail",
        "emailInvalid": "E-mail invalide",
        "emailPrimarEmpty": "(vide)",
        "emailPrimaryHelp": "Il est envoyé à toutes les adresses e-mail du profil utilisateur du propriétaire",
        "emailTypeCUSTOM": "E-mail à une adresse différente",
        "emailTypePRIMARY": "E-mail à moi-même",
        "entry": "Entrée",
        "from": "De",
        "name": "Nom",
        "recipient": "Destinataire",
        "storeLabel": "Sauvegarder les données du rapport",
        "submit": "Générer",
        "successNotificationText": "Le rapport a été créé.",
        "successNotificationTitle": "Succès !",
        "title": "Créer un nouveau rapport",
        "to": "À"
    },
    "it": {
        "email": "E-Mail",
        "emailInvalid": "E-Mail non valido",
        "emailPrimaryEmpty": "(vuoto)",
        "emailPrimaryHelp": "Va a tutti gli indirizzi e-mail dal profilo utente del proprietario.",
        "emailTypeCUSTOM": "E-mail a un indirizzo diverso",
        "emailTypePRIMARY": "E-mail a me",
        "entry": "Entrata",
        "from": "Da",
        "name": "Nome",
        "recipient": "Destinatario",
        "storeLabel": "Salvare i dati del rapporto",
        "submit": "Generare",
        "successNotificationText": "Il rapporto è stato creato.",
        "successNotificationTitle": "Successo!",
        "title": "Creare un nuovo rapporto",
        "to": "A"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-reporting-form-view {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 600px;

    .information-box {
        margin-bottom: 0;
    }
}
</style>
